<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="ClosedNotice__overlay">
    <b-card class="ClosedNotice">
      <b-card-body>
        <h3 v-text="$t('closed.title')" />
        <p v-html="participationConfig.closingMessage" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters('config', ['participationConfig', 'isPreview', 'isInteractive']),
  }
}
</script>

<style lang="scss">
.ClosedNotice {
  position: absolute;
  top: 50px;
  width: 600px;
  max-width: 100vw;
  margin: auto;
  left: 0;
  right: 0;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.4)
  }

  p {
    font-size: 1.4rem;
  }
}

</style>
